$background: #ecf0fa;
$default-color:#031b4e;

/*Color variables*/
$primary:#56a3a6; 
$secondary:#5f6d88;
$pink:#f10075;
$teal:#00cccc;
$purple:#673ab7;
$success:#22c03c;
$warning:#fbbc0b;
$danger:#ee335e;
$info:#00b9ff;
$orange:#fd7e14;
$dark:#3b4863;
$indigo:#ac50bb;
$white:#fff;
$black:#000;

/*gray variables*/
$gray-100:#ecf0fa;
$gray-200:#dde2ef;
$gray-300:#d0d7e8;
$gray-400:#b9c2d8;
$gray-500:#949eb7;
$gray-600:#737f9e;
$gray-700:#4d5875;
$gray-800:#364261;
$gray-900:#242f48;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow: -8px 12px 18px 0 #dadee8;

$dark-theme:#1f2940;

$border:#e3e8f7;

	

